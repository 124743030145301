@import "./colors.less";
.ant-layout {
  overflow-y: auto !important;
}
.crm-app-sidebar {
  background-color: #ffffff;
}

#crm-menu {
  .ant-menu-submenu-title span.user-letter-circle {
    height: 40px !important;
    width: 40px !important;
    padding: 12px 0;
    font-size: 16px;
    line-height: 15px;
    background-color: .crm_app() [--danger-color-light];
    color: .crm_app() [--danger-color];
    filter: none !important;
    text-align: center !important;
    border-radius: 100% !important;
  }
  .ant-menu-item {
    padding: 0 15px 0 15px !important;
    &.ant-menu-item-selected {
      background-color: #ffffff !important;
      .ant-menu-title-content {
        color: .crm_app() [--primary-color] !important;
      }
      .ant-menu-item-icon {
        background: .crm_app() [--primary-color-light];
        filter: grayscale(0) opacity(1);
      }
    }
  }
  .ant-menu-submenu {
    padding: 0 15px 0 15px !important;
  }
  .ant-menu-submenu-title {
    padding: 0 !important;
  }
  .ant-menu-submenu:first-child .ant-menu-submenu-title {
    margin-top: 16px;
  }
  .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu-item .ant-menu-item-icon {
    height: 36px !important;
    width: 40px !important;
    filter: grayscale(1) opacity(0.8);
    margin: 0 auto;
    display: block;
    padding: 8px 10px;
    background: #ffffff;
    border-radius: 7px;
  }
  .settings {
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
}
.crm-app-drawer {
  a,
  .ant-btn-link {
    color: .crm_app() [--primary-color] !important;
  }
  .ant-btn-primary {
    background: .crm_app() [--primary-color] !important;
    &.ant-btn-background-ghost {
      background: transparent !important;
    }
  }
}
.crm-app {
  background-color: .crm_app() [--app-background] !important;
  color: .crm_app() [--dark-color] !important;
  height: calc(100vh - 61px) !important;
  overflow-y: auto !important;
  .follow-up-pending-header {
    font-size: 16px !important;
    background-color: #eef2f9;
    border-radius: 5px;
    color: #e46e00;
    display: inline-block;
    padding: 3px 10px !important;
    margin-left: 15px !important;
    .ant-tag {
      font-size: 16px !important;
      border: 1px solid #e46e00 !important;
      background-color: #e46e00 !important;
      color: #eef2f9;
    }
  }
  .vc-status {
    background-color: #fb8000;
    display: inline-block;
    padding: 5px 10px 3px 10px;
    margin-left: 7px;
    line-height: 24px;
    border-radius: 5px;
    color: #ffffff;
  }
  .npscard {
    width: 100%;
    height: 42px;
    border-radius: 2px;
    margin-top: 15px;
    padding-top: 10px;
    p {
      text-align: center;
      color: #ffffff;
      font-size: 0.9rem !important;
    }
  }
  .ant-tabs-content {
    background-color: .crm_app() [--app-background];
  }
  #dash-filter-form {
    padding-left: 0 !important;
  }
  a,
  .ant-btn-link {
    color: .crm_app() [--primary-color] !important;
  }
  .header-more-options {
    font-size: 20px;
    color: #000000 !important;
    width: 30px;
    text-align: right;
    display: block;
  }

  .ant-page-header {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .ant-btn-primary {
    background: .crm_app() [--primary-color] !important;
    &.ant-btn-background-ghost {
      background: transparent !important;
    }
  }
  .ant-btn-dangerous {
    background: .crm_app() [--danger-color];
    &.ant-btn-background-ghost {
      background: transparent !important;
    }
  }
  .crm-outline-btn {
    color: .crm_app() [--primary-color];
    border: 1px solid .crm_app() [--primary-color];
  }
  .crm-card {
    .ant-card-head-title {
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
  }
  .nopadding-tab {
    .padding10 {
      padding: 10px !important;
    }
  }
  .ant-tabs-nav {
    margin: 0px !important;

    .ant-tabs-nav-list {
      padding-left: 20px !important;
    }
  }
  .ant-card.crm-tabs {
    .ant-tabs-content {
      background-color: #ffffff;
    }
  }
  .crm-tabs {
    .innertabs .ant-tabs-nav-wrap {
      background: .crm_app() [--app-background] !important;
      border-bottom: 1px solid #f0f0f0;
      .ant-tabs-tab {
        font-size: 14px;
        &.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: .crm_app() [--primary-color] !important;
        }
      }
    }
    .ant-tabs-nav-wrap {
      background: #ffffff;
      border-bottom: 1px solid #f0f0f0;
    }
    .ant-tabs-tab {
      font-size: 16px;
      padding-left: 10px;
      padding-right: 10px;
      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #000000 !important;
      }
    }
  }

  .ant-card.crm-tabs {
    .ant-tabs-content {
      background-color: #ffffff;
    }
  }

  .crm-table {
    .ant-table-title {
      padding: 0 0 10px 10px;
      background-color: .crm_app() [--app-background];
    }
    .ant-table-thead tr th {
      background: #eef2f9;
    }
    thead th {
      white-space: nowrap;
      word-break: break-word;
      word-break: break-all;
    }
    .ant-pagination li {
      min-width: 26px;
      height: 26px;
      line-height: 26px;
      button {
        font-size: 9px;
      }
    }
  }
  .ant-page-header {
    .ant-btn {
      color: .crm_app() [--primary-color] !important;
      border-color: .crm_app() [--primary-color] !important;
      padding: 6px 16px !important;
      height: 36px !important;
      margin-left: 8px;
    }
  }
  .topbar {
    border-bottom: 1px solid .crm_app() [--light-color] !important;
    .ant-page-header {
      padding: 10px 20px !important;
    }
  }
  .ant-card {
    border: 1px solid .crm_app() [--card-border-color] !important;
    border-radius: 5px !important;
    overflow: hidden;
  }
  .bg-app {
    background: .crm_app() [--app-background];
  }
  .bg-success-light {
    background: .crm_app() [--success-color-light];
  }
  .bg-warning-light {
    background: .crm_app() [--warning-color-light];
  }
  .bg-white {
    background: #ffffff;
  }
  .bg-secondary {
    background-color: .crm_app() [--secondary-color];
  }
  a.accent {
    color: .crm_app() [--primary-color];
    font-weight: 600;
    text-decoration: underline;
  }
  .accentCard {
    background-color: .crm_app() [--secondary-color];
    color: .crm_app() [--text-secondary-color];
    .ant-card-body {
      padding: 14px 16px;
      p {
        font-size: 0.8rem;
        margin: 0;
        &.title {
          font-size: 0.9rem;
          margin-bottom: 5px;
          span {
            color: .crm_app() [--text-dark-color];
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
      .drop-down-text {
        color: .crm_app() [--text-secondary-color] !important;
        display: inline-block;
      }
      .ant-btn.ant-btn-background-ghost {
        color: .crm_app() [--text-secondary-color];
        border-color: .crm_app() [--text-secondary-color];
        margin-top: 5px;
        margin-right: 5px;
        max-width: 90%;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
        }
      }
      .primary-ghost {
        color: .crm_app() [--primary-color] !important;
        border-color: .crm_app() [--primary-color] !important;
        max-width: 100% !important;
        margin: 0 !important;
      }
    }
  }
  .more_profiles_dd {
    min-width: 250px;
    .ant-dropdown-arrow {
      top: 6px;
      border-top-color: .crm_app() [--dark-color];
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: .crm_app() [--dark-color];
      box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
      left: 14px;
    }
    .list-container {
      background: .crm_app() [--dark-color];
      border-radius: 7px;
      padding: 10px;
      margin-top: 10px;
      .list-item {
        padding: 0;
        border-bottom: 1px solid .crm_app() [--light-color];
        p {
          color: .crm_app() [--text-light-color];
          margin: 0;
          font-size: 0.8rem;
          margin-bottom: 7px;
          &.title {
            font-size: 0.9rem;
            font-weight: bold;
            margin-bottom: 0px;
          }
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .text-dark {
    color: .crm_app() [--text-dark-color];
  }
  .text-secondary {
    color: .crm_app() [--text-secondary-color];
  }
  .text-primary {
    color: .crm_app() [--primary-color];
  }
  .border-primary {
    border: 1px solid .crm_app() [--primary-color];
  }
  .nopaddingcard {
    .ant-card-body {
      padding: 0;
    }
  }
  .tab-w-marker {
    padding-right: 30px;
    .small-tag {
      position: absolute;
      right: -3px;
      top: calc(50% - 10px);
      margin-right: 5px;
      padding: 0 6px;
      font-size: 10px;
      line-height: 16px;
    }
  }

  .infscroll-container {
    width: 100%;
    display: block;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
    .ant-divider-vertical {
      height: 80px;
      margin: 12px 8px;
    }
    .image-tab {
      position: relative;
      display: inline-block;
      width: 120px;
      text-align: center;
      padding: 5px;
      margin: 5px;
      border-radius: 5px;
      border: 2px solid transparent;
      &.active {
        border: 2px solid .crm_app() [--primary-color];
      }
      img {
        display: block;
        margin: 0 auto;
        height: 48px;
      }
      small {
        display: block;
        line-height: 13px;
        font-size: 10px;
        overflow: hidden;
        white-space: normal;
      }
      .ant-badge {
        position: absolute;
        top: 10px;
        left: 10%;
        &.pass {
          sup {
            background: .crm_app() [--success-color];
          }
        }
        sup {
          border-radius: 4px;
          line-height: 16px;
          font-size: 9px;
          height: 16px;
        }
      }
    }
  }
  .tab-w-img {
    .image-tab {
      text-align: center;
      img {
        display: block;
        margin: 0 auto;
        height: 48px;
      }
      small {
        display: block;
        line-height: 13px;
        font-size: 10px;
      }
      .ant-badge {
        position: absolute;
        top: 10px;
        left: 10%;
        &.pass {
          sup {
            background: .crm_app() [--success-color];
          }
        }
        sup {
          border-radius: 4px;
          line-height: 16px;
          font-size: 9px;
          height: 16px;
        }
      }
    }
  }
  .ant-tabs-dropdown-menu-item .tab-w-marker {
    position: relative;
  }

  .complaint-box {
    min-height: 50vh;
  }
  .collapse-right-arrow {
    border-left: 0px !important;
    border-bottom: 0px !important;
    border-right: 0px !important;
    border-radius: 0 0 0 4px;
    overflow: hidden;
    .ant-collapse-item {
      border-left: 0px !important;
      border-bottom: 0px !important;
      border-right: 0px !important;

      .ant-collapse-header {
        border-radius: 0px !important;
        border-left: 0px !important;
        border-bottom: 0px !important;
        background: #ffffff;
      }
      .ant-collapse-arrow {
        margin: 0px;
        position: absolute;
        right: 20px;
        top: calc(50% - 6px);
      }
      .ant-collapse-content {
        background: #ffffff !important;
      }
    }
  }
  .crm-timeline {
    .timeline-details {
      border-radius: 5px;
      background: .crm_app() [--app-background];
      padding: 10px;
      // border:1px solid .crm_app()[--light-color];
    }
    &.white {
      .timeline-details {
        background: #ffffff !important;
      }
    }
  }
  .namecard-topbar {
    .border-right {
      border-right: 1px solid .crm_app() [--divider-color];
    }
  }
  .border-right {
    border-right: 1px solid .crm_app() [--card-border-color];
  }
  .namecard-topbar {
    .ant-col {
      font-size: 0;
      line-height: 0;
    }
    span,
    a {
      line-height: 20px !important;
      font-weight: 400;
      font-size: 12px !important;
      &.title {
        line-height: 20px !important;
        font-size: 16px !important;
      }
    }
  }
  .filters-formbar {
    .crm-form {
      padding: 0 !important;
      .ant-form-item {
        margin-bottom: 0 !important;
      }
      .ant-col {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }
  }

  .text-success {
    color: .crm_app() [--success-color] !important;
  }
  .text-warning {
    color: .crm_app() [--warning-color];
  }
  .text-danger {
    color: .crm_app() [--danger-color];
  }
  .wide-btn {
    min-width: 100px;
  }
  .contact-select {
    min-width: 200px;
  }
  .ant-tag {
    border-radius: 3px !important;
    background-color: #ffffff !important;
    color: .crm_app() [--text-secondary-color];
    border-color: .crm_app() [--text-secondary-color];
    margin: 2px;
    &.ant-tag-blue {
      color: .crm_app() [--primary-color];
      border-color: .crm_app() [--primary-color];
    }
    &.ant-tag-red {
      color: .crm_app() [--danger-color];
      border-color: .crm_app() [--danger-color];
    }
    &.ant-tag-green {
      color: .crm_app() [--success-color];
      border-color: .crm_app() [--success-color];
    }
    &.ant-tag-purple {
      color: #531dab !important;
      background: #f9f0ff !important;
      border-color: #d3adf7 !important;
    }
    &.solid {
      &.ant-tag-blue {
        color: .crm_app() [--primary-color] !important;
        background-color: .crm_app() [--primary-color-light] !important;
        border-color: .crm_app() [--primary-color-light] !important;
      }
      &.ant-tag-red {
        color: .crm_app() [--danger-color] !important;
        background-color: .crm_app() [--danger-color-light] !important;
        border-color: .crm_app() [--danger-color-light] !important;
      }
    }
    &.solid-full {
      &.ant-tag-red {
        background-color: .crm_app() [--danger-color] !important;
        border-color: .crm_app() [--danger-color] !important;
        color: #ffffff;
      }
    }
  }
  .rxcollapse {
    .ant-collapse-item {
      .ant-collapse-header {
        background-color: #ffffff !important;
        cursor: pointer;
      }
      &.ant-collapse-item-active {
        .ant-collapse-header {
          background-color: .crm_app() [--primary-color-light] !important;
          color: .crm_app() [--primary-color] !important;
          border-bottom: 1px solid .crm_app() [--primary-color] !important;
        }
      }
    }
  }
  .ant-divider.dark {
    border-left: 1px solid rgba(0, 0, 0, 0.9) !important;
  }
  .status-button {
    min-width: 120px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 16px;
    font-weight: 600;
    color: #000 !important;
  }
  .crm-mobile-list {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
  }
}
.crm-modal {
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: .crm_app() [--primary-color] !important;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background: .crm_app() [--primary-color] !important;
    border-color: .crm_app() [--primary-color] !important;
  }
}
.crm-form {
  .ant-btn-primary {
    border-color: .crm_app() [--primary-color] !important;
    background: .crm_app() [--primary-color] !important;
  }
  .ant-btn-default {
    border: 1px solid .crm_app() [--primary-color] !important;
    color: .crm_app() [--primary-color] !important;
    background-color: #fff !important;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }
  .call-log-associated {
    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      background-color: .crm_app() [--primary-color-light] !important;
      border-color: .crm_app() [--primary-color-light] !important;
      color: .crm_app() [--primary-color] !important;
    }
  }
  .ant-radio-button-wrapper {
    border: 1px solid #d9d9d9 !important;
    border-left: none !important;
  }
  .ant-radio-button-wrapper:first-child {
    border: 1px solid #d9d9d9 !important;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
  .ant-form-item-label {
    width: 100%;
    text-align: left;
    label {
      &::after {
        display: none;
      }
    }
  }
  .ant-radio-button-wrapper.pass,
  .ant-radio-button-wrapper.fail {
    border: 1px solid .crm_app() [--light-color] !important;
  }
  .pass.ant-radio-button-wrapper-checked:hover,
  .pass.ant-radio-button-wrapper-checked {
    background: .crm_app() [--success-color] !important;
    border: 1px solid .crm_app() [--success-color] !important;
  }
  .fail.ant-radio-button-wrapper-checked:hover,
  .fail.ant-radio-button-wrapper-checked {
    background: .crm_app() [--danger-color] !important;
    border: 1px solid .crm_app() [--danger-color] !important;
  }
}
.ant-modal.form-modal {
  label {
    font-weight: 600 !important;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .crm-form {
    padding: 0 !important;
    .ant-form-item-label label {
      color: rgba(0, 0, 0, 0.85) !important;
    }
    .cover {
      padding: 16px 16px 0 16px !important;
      margin: 0 !important;
    }
    .button-row {
      margin-top: 16px !important;
      padding: 0 24px 24px 24px !important;
      border-top: 1px solid .crm_app() [--card-border-color];
      background-color: .crm_app() [--app-background];
      .ant-form-item {
        margin-bottom: 0px !important;
      }
    }
  }
  .crm-form.cover,
  .ant-row.cover {
    padding: 16px 24px 0 24px !important;
    margin: 0 !important;
    .button-row {
      margin-left: -24px;
      margin-right: -24px;
      margin-top: 10px !important;

      .text-dark {
        color: #000000 !important;
      }
    }
  }
  .ant-row.cover {
    padding: 16px 8px 0 8px !important;
    .button-row {
      margin-left: -8px !important;
      margin-right: -8px !important;
      margin-top: 10px !important;
      padding: 24px 16px !important;
      width: calc(100% + 16px) !important;
      background-color: .crm_app() [--app-background];
    }
    .button-row-2 {
      margin-left: -18px !important;
      margin-right: -18px !important;
      margin-bottom: -10px !important;
      margin-top: 10px !important;
      padding: 24px 16px !important;
      width: calc(100% + 32px) !important;
      background-color: .crm_app() [--app-background];
    }
    .nomrg {
      margin: 0px !important;
    }
  }
}
.ant-scrolling-effect .App {
  filter: blur(5px);
  -webkit-filter: blur(5px);
}
.more-filters {
  .ant-tag {
    padding: 2px 4px !important;
    line-height: 9px !important;
    margin-left: 10px !important;
    border-radius: 10px !important;
  }
}
.crm-app-sidebar {
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title {
    display: block !important;
    align-items: center !important;
  }
}
.floating-audit {
  position: absolute;
  top: 8px;
  right: 5px;
}
.crm-dashboard {
  .dashboard-card-1 {
    .ant-card-body {
      padding: 16px 24px !important;
    }
    .card-item {
      font-size: 18px;
    }
    .icon-container {
      width: 90px;
      height: 90px;
      border-radius: 100%;
      display: block;
      svg {
        width: 85px;
        height: 85px;
        padding-top: 5px;
      }
    }
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      display: block;
      &.success {
        background-color: .crm_app() [--success-color];
      }
    }
    .table-container {
      table {
        width: 100%;
        thead,
        tbody {
          background-color: .crm_app() [--app-background];
          padding-left: 10px;
          padding-right: 10px;
          overflow: hidden;
        }
        thead {
          border-radius: 7px 7px 0 0;
          th {
            padding-top: 7px;
            &:first-child {
              border-radius: 7px 0 0 0;
              padding-left: 7px;
            }
            &:last-child {
              border-radius: 0 7px 0 0;
              padding-right: 7px;
            }
          }
        }
        tbody {
          border-radius: 0 0 7px 7px;
          padding-bottom: 10px;
          tr {
            td {
              &:first-child {
                padding-left: 7px;
              }
              &:last-child {
                padding-right: 7px;
              }
            }
            &:last-child {
              td {
                padding-bottom: 7px;
                &:first-child {
                  border-radius: 0 0 0 7px;
                }
                &:last-child {
                  border-radius: 0 0 7px 0;
                }
              }
            }
          }
        }
        th,
        td {
          padding: 3px;
          font-weight: 400;
          text-align: right;
          &:first-child {
            text-align: left !important;
          }
        }
        th {
          color: .crm_app() [--text-secondary-color];
        }
        td {
          font-weight: 900;
          color: .crm_app() [--primary-color];
          &:first-child {
            font-weight: 400;
            color: #000;
          }
        }
        tfoot {
          td {
            padding-top: 7px;
            &:first-child {
              padding-left: 7px;
            }
            &:last-child {
              padding-right: 7px;
            }
            font-weight: 700 !important;
          }
        }
      }
    }
  }
}
.crm-mobile-filters {
  background-color: .crm_app() [--app-background] !important;
  padding: 10px 20px !important;
  .filters-formbar .crm-form .ant-form-item {
    margin-bottom: 10px !important;
  }
}
.history-timeline-date {
  min-width: 70px;
}
.history-timeline-details {
  width: calc(100% - 70px);
}
.activity-history-list {
  padding-right: 0px !important;
  padding-left: 10px !important;
}
.details-page-fixed-header-comp {
  max-height: calc(100vh - 155px);
  overflow-y: scroll;
}
.visible-only-mobile {
  display: none;
}
@media (max-width: 767px) {
  .ant-page-header {
    padding-right: 20px !important;
  }
  .crm-app .crm-tabs .ant-tabs-tab {
    margin-left: 10px;
  }
  .filters-formbar {
    .ant-input-affix-wrapper {
      height: 38px !important;
      .ant-input-prefix {
        padding-bottom: 3px !important;
      }
    }
    .ant-col-xs-14 {
      width: calc(100vw - 100px - 25px) !important;
      max-width: calc(100vw - 100px - 25px) !important;
      flex: 0 0 calc(100vw - 100px - 25px) !important;
    }
  }
  .list-card {
    .text-right {
      text-align: unset !important;
    }
  }
  .ant-picker-panels {
    flex-direction: column;
  }
  .ant-popover {
    max-width: 100vw !important;
  }
  .filter-applied-count-tag {
    display: none !important;
  }
  .ant-popover-inner-content .crm-form {
    padding: 0 !important;
  }
  .history-timeline-date {
    width: 100%;
  }
  .history-timeline-details {
    width: 100%;
  }
  // .lead-details-tabs .ant-tabs-nav,
  // .ticket-details-tabs .ant-tabs-nav {
  //   display: none !important;
  // }
  .complaint-box {
    min-height: unset !important;
  }
  .details-page-area {
    max-height: calc(100vh - 52px);
    overflow-y: auto;
  }
  .details-page-fixed-header-comp {
    max-height: unset;
  }
  .associated-leads,
  .associated-tickets {
    .pagination-bar {
      padding-left: 0px !important;
    }
    .filter-bar p {
      padding-right: 0px !important;
    }
  }
  // .visible-only-mobile {
  //   display: block;
  // }
  .create-lead-form,
  .create-ticket-form {
    max-height: 90vh;
    overflow-y: auto;
  }
  .ant-layout-sider {
    position: absolute;
    z-index: 9999;
    height: 100vh;
  }
  .ticket-appointment-radios.ant-radio-button-wrapper {
    min-height: 130px !important;
  }
  .crm-app .crm-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600 !important;
  }
  .crm-app {
    .update-form {
      border-top: 1px solid .crm_app() [--divider-color];
    }
    .data-update-form-left {
      border-bottom: 1px solid .crm_app() [--divider-color];
      .ant-collapse {
        margin-top: 30px;
      }
    }
    .data-gap {
      display: flex !important;
      height: 20px;
      background-color: .crm_app() [--app-background];
      border: none;
      transform: scaleX(1.3) scaleY(0.95);
    }
  }
}

.p-b-xs {
  padding-bottom: 5px;
}

.link-button {
  user-select: none;
}

.substitute-name {
  text-transform: uppercase;
}

.medicine-replaced {
  width: auto;
  background-color: #232c42;
  border-radius: 10px;
}

.medicine-replaced .ant-notification-notice-message {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #fff;
}

.substituteModal {
  .ant-modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 160px);
  }
}
.chat-card {
  border-radius: 6px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
  margin-bottom: 10px !important;
  border: none !important;
  position: relative;
  padding-bottom: 10px;
  min-width: 50%;
  max-width: 90%;
  .datetime {
    font-size: 10px !important;
    position: absolute;
    bottom: 4px;
  }
  &.right {
    background-color: #37c2d8 !important;
    color: #fff !important;
    .datetime {
      right: 10px;
    }
  }
}
.chat-bot-page,
.crm-chat-widget {
  .chat-loading {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    height: 40px; /* Adjust height as needed */
    margin-top: 16px;
    margin-left: 10px;
  }

  .dot-flashing {
    width: 10px;
    height: 10px;
    background-color: #aaa; /* Green color, change as desired */
    border-radius: 50%;
    margin: 0 4px; /* Adjust spacing between dots */
    animation: dot-flashing 1.5s infinite ease-in-out;
    display: inline-block;
  }

  @keyframes dot-flashing {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
  .launcher {
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 0;
    position: fixed;
    right: 0;
    z-index: 9999;
    .ant-btn {
      padding: 14px;
      width: 60px !important;
      height: 60px !important;
    }
  }
  .tab-row {
    padding: 18px !important;
    box-sizing: border-box;
  }
  .conversation {
    bottom: 10px;
    display: flex;
    flex-direction: column;
    margin: 0 15px 5px 0;
    position: fixed;
    right: 0;
    z-index: 2;
    .related-view {
      right: 40vw;
      // right: calc(30vw + 20px);
      background: #f7f8fb;
      position: fixed;
      height: calc(100vh - 30px);
      overflow-y: auto;
      width: 55vw;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
      box-sizing: border-box;
      border-radius: 12px 0 0 12px;
      padding: 0;
      li {
        color: #4da9d0;
      }
    }
    .module-selector-label {
      font-weight: 600;
      line-height: 14px;
      margin-left: 24px;
      margin-bottom: 0px;
      position: absolute;
      top: -21px;
      width: calc(100% - 30px);
      text-align: center;
    }
    .module-selector {
      margin-left: 24px;
      width: 180px;
      text-align: center;
      .ant-select-selector {
        border-radius: 100px !important;
        border-color: #66b2b2;
      }
    }
    .title {
      font-weight: 600;
      font-size: 1.1rem;
      margin-bottom: 5px !important;
      &.line-below {
        margin-bottom: 20px !important;
        position: relative;
        &::after {
          content: "";
          width: 70px;
          background-color: #4da9d0;
          height: 3px;
          position: absolute;
          bottom: -8px;
          left: 0px;
        }
      }
    }
    .answer-container {
      padding-bottom: 16px;
      position: relative;
      .copy-answer {
        position: absolute;
        bottom: -11px;
        right: 2px;
      }
    }
    .left {
      padding-bottom: 30px;
      position: relative;
      .copy-answer {
        position: absolute;
        bottom: 4px;
        right: 4px;
      }
    }
    .card {
      z-index: 1;
      background: #fafafe;
      border-radius: 12px;
      width: 40vw;
      height: calc(100vh - 30px);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
      overflow: hidden;

      .header-area {
        background-color: #fff;
        padding: 8px 18px;
        height: 85px;
        box-sizing: border-box;
      }
      .chat-area {
        padding: 18px;
        height: calc(100% - 180px);
        box-sizing: border-box;
        overflow-y: auto;
        .message-container {
          padding: 5px;
          .left,
          .right {
            max-width: 80%;
            min-width: 40%;
            border-radius: 10px;
            padding: 7px 10px 12px 10px;
            background-color: #ffffff;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
          }
          .right {
            background-color: #eaeafd;
          }
        }
      }
      .question-area {
        background-color: #fff;
        padding: 18px;
        height: 95px;
        box-sizing: border-box;
      }
    }
    &.tab-view {
      .card {
        z-index: 99999;
        width: 85vw;
      }
      .related-view {
        right: 0;
        height: calc(100% - 180px);
        display: block;
        position: relative;
        width: 100%;
        box-shadow: none !important;
        box-sizing: border-box;
        border-radius: 0 !important;
      }
    }
  }

  .ant-tag {
    border-radius: 10px !important;
    &.btn {
      cursor: pointer;
    }
  }
  .snippet-container {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    background-color: #eaeafd;
    padding: 5px 10px 25px;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
    &::after,
    &::before {
      background: linear-gradient(-45deg, #fafafe 10px, transparent 0),
        linear-gradient(45deg, #fafafe 10px, transparent 0);
      background-position: left bottom;
      background-repeat: repeat-x;
      background-size: 12px 32px;
      content: " ";
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 25px;
    }
    &::before {
      background: linear-gradient(-45deg, #eaeafd 10px, transparent 0),
        linear-gradient(45deg, #eaeafd 10px, transparent 0);
      background-position: left bottom;
      background-repeat: repeat-x;
      background-size: 12px 32px;
      content: " ";
      display: block;
      position: absolute;
      top: -20px;
      left: 0px;
      width: 100%;
      height: 25px;
    }
  }
}

.chat-bot-page {
  height: 100vh;
  height: 100dvh;
  display: block;
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
  overflow: auto;
  .conversation {
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh !important;
    height: 100dvh !important;
    width: 100%;
    position: relative;
    z-index: 2;
    display: block;
    bottom: 0;
    .related-view {
      right: 0;
      z-index: 1;
      // right: calc(30vw + 20px);
      // background: #f7f8fb;
      position: relative;
      display: inline-block;
      // flex: 0 0 55%;

      // height: calc(100vh - 30px);
      height: 100vh !important;
      height: 100dvh !important;
      overflow-y: auto;
      width: 55%;
      margin-right: 0px !important;
      box-shadow: none;
      // box-sizing: border-box;
      border-radius: none;
      // padding: 0;
      // li {
      //   color: #4da9d0;
      // }
    }

    .card {
      position: relative;
      display: inline-block;
      width: 45%;
      z-index: 2;
      // background: #fafafe;
      border-radius: 0px;
      // width: 40vw;
      height: 100vh;
      height: 100dvh;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
      overflow: hidden;
    }
    &.tab-view {
      .card {
        z-index: 99999;
        width: 100vw;
      }
      .related-view {
        right: 0;
        height: calc(100% - 180px) !important;
        display: block;
        position: relative;
        width: 100%;
        box-shadow: none !important;
        box-sizing: border-box;
        border-radius: 0 !important;
      }
    }
  }
}

.admin-upload-component {
  background: linear-gradient(
      0deg,
      rgba(55, 194, 216, 0.1),
      rgba(55, 194, 216, 0.1)
    ),
    #ffffff;
  /* Blue */
  border: 1px dashed #37c2d8;
  border-radius: 5px;
  text-align: center;
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #223042;
  }
  .sub-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #223042;
  }
  .upload-image {
    width: 161.48px;
    height: 161.48px;
  }
}
.admin-file-component {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 14px;
  &.danger {
    border-color: #e1454e !important;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #223042;
  }
  .sub-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #223042;
  }
  .progress-bar {
    height: 6px;
    border-radius: 5px;
    background: #9ba2a0;
    &.success {
      background: #37cb95;
    }
    &.danger {
      background: #e1454e;
    }
  }
  .percent {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    &.success {
      color: #37cb95;
    }
    &.danger {
      color: #e1454e;
    }
  }
}
.admin-error-message {
  color: #e1454e;
  text-align: center;
}

.btn-success-outlined {
  border: 1px solid .crm_app() [--success-color] !important;
  color: .crm_app() [--success-color] !important;
}
.btn-danger-outlined {
  border: 1px solid .crm_app() [--danger-color] !important;
  color: .crm_app() [--danger-color] !important;
}
