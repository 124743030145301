// root colors

.page-wrapper {
  height: 100vh;
  position: relative;
}

.page-wrapper {
  .main {
    margin: 7em auto;
    padding: 30px 20px;
    box-shadow: 0 11px 35px 2px rgb(0 0 0 / 14%);
    min-height: 556px !important;
  }

  .login-form {
    margin: 4em auto;
    padding: 50px 40px;
    border-radius: 6px;
  }
}

.heading-text {
  text-align: center;
  font-size: 32px !important;
  font-weight: 600;
  color: var(--heading-color);
  span {
    color: var(--primary-color) !important;
    font-weight: 700 !important;
  }
}

// margin css
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt50 {
  margin-top: 50px;
}

.fw1 {
  font-weight: 100;
}
.fw2 {
  font-weight: 200;
}
.fw3 {
  font-weight: 300;
}
.fw4 {
  font-weight: 400;
}
.fw5 {
  font-weight: 500;
}
.fw6 {
  font-weight: 600;
}
.fw7 {
  font-weight: 700;
}
.fw8 {
  font-weight: 800;
}
.fw9 {
  font-weight: 900;
}

//for scrolling in prescription or document view popup
.fileOverflow {
  overflow: scroll;
  height: 75vh;
}

.fileOverflow .ant-image-img {
  display: block;
  height: 100%;
  width: auto;
  margin: auto;
  transform-origin: top center;
}

.fileOverflowScrolled.fileOverflow .ant-image-img {
  transform-origin: 0 0;
}

//to align steps in same line
.stepMargin .ant-steps-item-icon {
  margin-top: 5px;
}

//margin bottom
.mb5 {
  margin-bottom: 5px;
}

.mb12 {
  margin-bottom: 12px;
}

//padding for select button
.selectp5 {
  padding: 5px;
}

//header and body content
.headerDivider {
  border-top: 2px solid #163b6d52;
}
.uploadIcon {
  enable-background: new 0 0 500 500;
  height: 14px;
}

//right align
.right {
  float: right;
}

//left alignment
.left {
  float: left;
}

.clear {
  clear: both;
}

//for upload add icon
.addIconHolder button.ant-btn {
  padding: 0;
  height: 100%;
}

.addIconHolder {
  border-left: 1px solid #d0d8e2;
  margin: 0px;
}

.addIconHolder > span {
  height: 100%;
  display: block;
}

.addIconHolder .ant-upload.ant-upload-select {
  display: block;
  padding-left: 10px;
}

.addIconHolder .ant-space.ant-space-horizontal.ant-space-align-center {
  gap: 0px !important;
}

.doctor-gray-button,
.doctor-border-button:focus,
.doctor-border-button:hover {
  border: none;
  color: #23bcb5 !important;
  background-color: white !important;
  text-shadow: none !important;
}

.doctor-gray-border {
  border: 1px solid #163b6d38 !important;
  /* box-shadow: none !important; */
}

//margin bottom 0
.mb0 {
  margin-bottom: 0;
}

.mb30 {
  margin-bottom: 30px;
}
//padding left
.pl10 {
  padding-left: 10px;
}

.hms-ui-c-cOcQin {
  z-index: 1000000000 !important;
}

.hms-ui-c-hqSodN .ant-tag {
  display: none !important;
}
