@import "./colors.less";

.doctor-app {
  background-color: .doctor_app() [--app-background];
}

.doctor-grey-background {
  background-color: .doctor_app() [--primary-tag-color] !important;
}

.doctor-app-sidebar {
  background-color: .doctor_app() [--sidebar-background] !important;

  .ant-menu {
    background: .doctor_app() [--sidebar-background] !important;
    border-right: 1px solid .doctor_app() [--sidebar-background] !important;

    .ant-menu-sub.ant-menu-inline {
      background-color: .doctor_app() [--primary-tag-color] !important;
    }
  }
}

.doctor-top-border {
  border-radius: 5.40541px !important;
  overflow: hidden;
}

.doctor-box-shadow {
  box-shadow: .doctor_app() [--box-shadow] !important;
}

.bottom-box-shadow {
  box-shadow: 0px 5.22414px 17.4138px #9bb9c8 !important;
}

.doctor-light-background {
  background-color: .doctor_app() [--app-background] !important;
}

.doctor-secondary-color {
  color: .doctor_app() [--secondary-color] !important;
}

.doctor-secondary-border {
  border: 1px solid .doctor_app() [--secondary-color] !important;
  box-shadow: none !important;
}

.doctor-secondary-color-background {
  background-color: .doctor_app() [--secondary-color] !important;
}

.doctor-primary-color-background {
  background-color: .doctor_app() [--primary-color] !important;
}

.doctor-white-color-background,
.ant-btn[disabled].doctor-white-color-background {
  background-color: white !important;
  border: 1px solid white !important;
  box-shadow: none !important;

  .white-text {
    color: .doctor_app() [--primary-color] !important;
    opacity: 0.8;
  }
}

.ant-divider-vertical {
  border-left: 1px solid #8eaac3 !important;
}

.bgTransparentBtn {
  background: transparent !important;
  border: none !important;
  height: auto !important;
  width: auto !important;
}

.fWeight400 {
  font-weight: 400 !important;
}

.doctor-primary-color {
  color: .doctor_app() [--primary-color] !important;
}

.doctor-primary-button {
  background: .doctor_app() [--primary-color] !important;
  opacity: 0.34;
}

.doctor-border-button,
.doctor-border-button:focus,
.doctor-border-button:hover {
  border: 1px solid .doctor_app() [--secondary-color] !important;
  color: .doctor_app() [--secondary-color] !important;
  background-color: white !important;
  text-shadow: none !important;
}

.doctor-secondary-button,
.doctor-secondary-button:focus,
.doctor-secondary-button:hover {
  border: 1px solid .doctor_app() [--secondary-color] !important;
  background-color: .doctor_app() [--secondary-color] !important;
  color: white !important;
  box-shadow: 0px 4.6608px 15.536px #9bb9c8 !important;
}

.boxShadowNone {
  box-shadow: none !important;
}

.doctor-secondary-button2 {
  border: 1px solid .doctor_app() [--secondary-color] !important;
  background-color: .doctor_app() [--secondary-color] !important;
  color: white !important;
}

.btnItemCenter {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: auto !important;
  width: auto !important;
}

.doctor-secondary-button2:focus,
.doctor-secondary-button2:hover {
  border: 1px solid .doctor_app() [--secondary-color] !important;
  background-color: .doctor_app() [--secondary-color] !important;
  color: white !important;
  box-shadow: 0px 4.6608px 15.536px #9bb9c8 !important;
}

.dark-border-button,
.dark-border-button:focus,
.dark-border-button:hover {
  border: 1px solid .doctor_app() [--dark-color] !important;
  color: .doctor_app() [--dark-color] !important;
  background-color: white !important;
}

.doctor-padding-15 {
  padding: 15px 40px;
}

.ant-btn.doctor-padding-15 {
  height: auto !important;
}

.doctor-app-box-shadow,
.doctor-app-box-shadow:focus {
  box-shadow: .doctor_app() [--box-shadow] !important;
}

.border-radius5 {
  border-radius: 5px !important;
  overflow: hidden !important;
}

.padding-main {
  padding: 10px 20px !important;
}

.phoneNumberColor {
  color: #23bcb5 !important;
}
.dropdownTextColor {
  color: #23bcb5 !important;
}
// .ant-select-single.ant-select-open .ant-select-selection-item {
//   color: #23BCB5 !important;
// }

.patientForm .ant-select-selection-item {
  color: #23bcb5 !important;
}

.timepickers {
  color: #163b6d !important;
}

.addApp .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

.timepicker .ant-select-selection-item {
  color: #163b6d !important;
}

.patientForm .ant-picker-large .ant-picker-input > input {
  color: #23bcb5 !important;
}
.nomarginLR20 {
  margin-left: -20px !important;
}
.borderRightGray {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.avtarOPD {
  height: 40px !important;
  width: 40px !important;
  align-items: center !important;
  margin-top: -5px !important;
  display: inline-flex !important;
  justify-content: center !important;
}

.supportPopup .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.paddingLR20 {
  padding: 0px 20px !important;
}
.paddingLR10 {
  padding: 0px 10px !important;
}
.paddingLR24 {
  padding: 0px 24px !important;
}
.mt5 {
  margin-top: 5px !important;
}

.fullHeightDiv {
  position: relative !important;
  height: 100vh !important;
}

.alignVerticals {
  display: inline-flex !important;
  align-items: center !important;
}

.drawerChat .ant-drawer-header {
  padding: 0px !important;
}

.bottomFixedButton {
  width: 100% !important;
  bottom: 10px !important;
  position: absolute !important;
}

.paddingLR15 {
  padding: 0px 15px !important;
}

.paddingLR10 {
  padding: 0px 10px !important;
}

.doctor-color-heading {
  background: .doctor_app() [--sidebar-background] !important;
  border-radius: 5.40541px 5.40541px 0px 0px;
  padding: 10px 20px;
}
.doctor-color-heading-without-radius {
  background: .doctor_app() [--sidebar-background] !important;
  padding: 10px 20px;
}
.ant-modal-close-x {
  color: #163b6d !important;
}

.resetPinHead {
  color: #163b6d;
  font-weight: 400;
}

.font15 {
  font-size: 15px !important;
}

.fw550 {
  font-weight: 550 !important;
}

.resetPin .ant-input-suffix {
  width: 50px !important;
  justify-content: center !important;
  font-size: 18px !important;
}

.resetPin input {
  // letter-spacing: 80px !important;
  // padding-left: 30px !important;
  // min-height: 60px !important;

  letter-spacing: 20px !important;

  padding-left: 20px !important;

  min-height: 60px !important;
}

.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  display: inline-flex !important;
  align-items: center !important;
}

.cameraSwitch .st0 {
  fill: rgba(22, 59, 109, 0.19) !important;
}

.ant-menu-submenu-popup {
  left: 85px !important;
}

.side_popup_menu {
  font-size: 14px;
  line-height: 0;
  text-align: left;
  left: 80px !important;
  margin: 0;
  padding: 0;
  box-shadow: none;
  list-style: none;
}

.resetPin input[type="password"] {
  -webkit-text-security: square;
  font-size: 30px !important;
}

.colorDark {
  color: #163b6d !important;
}

.max-subHead-color {
  color: #163b6d !important;
  opacity: 0.5 !important;
}

.max-subHead-color2 {
  color: #163b6d !important;
  opacity: 0.5 !important;
}

.joinVideoCallModal .ant-modal-title {
  font-weight: 700 !important;
}

.ant-divider-dashed {
  border-color: #163b6d;
  opacity: 0.2;
}

.prescriptionModal .ant-modal-title {
  font-weight: 600;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #23bcb5;
  border-color: #23bcb5;
}

.dateColors {
  color: #163b6d !important;
  opacity: 0.5;
  font-weight: 400;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #23bcb5 !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #23bcb5;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #23bcb5;
}

.ant-radio-checked .ant-radio-inner:focus {
  border-color: #23bcb5;
}

.doctor-color-heading2 {
  background: .doctor_app() [--sidebar-background] !important;
  border-radius: 5.40541px 5.40541px 0px 0px;
  padding-left: 21px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.tagSpace .ant-tag {
  margin-right: 0px !important;
}

.pb0 {
  padding-bottom: 10px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl5 {
  padding-left: 5px !important;
}
.pr5 {
  padding-right: 5px !important;
}

.doctor-color-heading3 {
  background: .doctor_app() [--sidebar-background] !important;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

/// card css

.doctor-app-light-card .ant-card-body {
  background-color: .doctor_app() [--sidebar-background] !important;
  padding: 15px !important;
}

.doctor-app-dark-card .ant-card-head {
  background-color: .doctor_app() [--sidebar-background] !important;
}

.doctor-app-dark-card .ant-card-body {
  padding-bottom: 15px !important;
}
.doctor-app-dark-modal .ant-modal-body {
  background-color: #dfeaf1 !important;
  overflow: scroll;
}

.dark-background {
  background-color: .doctor_app() [--sidebar-background] !important;
}

// tags

.doctor-tags {
  border-radius: 8.10811px;
}

.doctor-primary-color-tag {
  background: .doctor_app() [--primary-tag-color] !important;
  border-radius: 8.10811px;
  color: .doctor_app() [--primary-color] !important;
  opacity: 0.5;
}

.doctor-secondary-color-tag {
  background: .doctor_app() [--secondary-tag-color] !important;
  border-radius: 8.10811px;
  opacity: 0.8;
  color: .doctor_app() [--secondary-color] !important;
}

.doctor-white-color-tag {
  background: white !important;
  border-radius: 8.10811px;
  opacity: 0.8;
  color: .doctor_app() [--primary-color] !important;
}

.doctor-search .ant-btn.ant-input-search-button {
  height: 40px !important;
  border: 1px solid var(--secondary-color) !important;
  background-color: var(--secondary-color) !important;

  svg {
    color: white !important;
  }
}

.doctor-pimary-color-light {
  color: .doctor_app() [--primary-tag-color] !important;
}

.selected-patient {
  background-color: #5ab9b421 !important;
  border-right: 8px solid var(--secondary-color);
}
