@import "auth-pages";
@import "layouts";
@import "forms";
@import "./colors.less";
@import "./crm-app.less";
@import "./doctor-app.less";
@import url("https://use.typekit.net/khm6qvr.css");

// @font-face {
//   font-family: 'proxima-nova';
//   src: url('../assets/font/Proxima_Nova/MarkSimonson-ProximaNova.ttf') format('truetype'),
//   url('../assets/font/Proxima_Nova/MarkSimonson-ProximaNovaBold.ttf') format('truetype'),
//   url('../assets/font/Proxima_Nova/MarkSimonson-ProximaNovaMedium.ttf') format('truetype'),
//   url('../assets/font/Proxima_Nova/MarkSimonson-ProximaNovaSemibold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

// body {
//   font-family: 'proxima-nova';
// }

.custom-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.white-text {
  color: white !important;
}
.light-blue-text {
  color: #37c2d8 !important;
  font-size: 30px;
}

.opacity03 {
  opacity: 0.3 !important;
}

.opacity05 {
  opacity: 0.5 !important;
}

.opacity07 {
  opacity: 0.7 !important;
}
small {
  font-size: 12px !important;
}

/* Override Ant Design InputNumber styles */
.ant-input-number-handler-wrap {
  display: none; /* Hide the spin buttons */
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.ant-page-header-heading-title {
  color: #000000 !important;
}

.ant-modal-title {
  color: #223042 !important;
  font-weight: 600 !important;
}

.ant-modal-header {
  border: 1px solid #d9d9d9 !important;
}

.ant-picker-suffix {
  color: rgba(0, 0, 0, 0.85) !important;
}

.flipIcon {
  transform: rotateY(180deg) !important;
}

.whiteRoundButton {
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  background: white;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.headColor {
  color: #223042 !important;
  font-weight: 600 !important;
}

td .ant-checkbox {
  display: none !important;
}
td .ant-checkbox-checked {
  display: block !important;
}

.ant-table-row:hover td .ant-checkbox {
  display: block !important;
}

.overflowhide {
  overflow: hidden !important;
  overflow-y: scroll !important;
}

.selectionRows .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #76d5e4 !important;
  border-color: #76d5e4 !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #e5f7fa !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #e6f7ff !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #37c2d8 !important;
  font-weight: 400 !important;
}

// .ant-table-tbody > tr.ant-table-row-selected:hover > td {
//   background: rgba(105, 209, 226, 0.17);
// }

td .ant-checkbox {
  display: none !important;
}
td .ant-checkbox-checked {
  display: block !important;
}

.ant-table-row:hover td .ant-checkbox {
  display: block !important;
}

.font12 {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px !important;
}

.font16 {
  font-size: 16px !important;
}

.font17 {
  font-size: 17px !important;
}

.font18 {
  font-size: 18px !important;
}
.branchColor {
  color: #163b6d !important;
  opacity: 0.5;
}

.font15 {
  font-size: 15px !important;
}

.faddedButton {
  background-color: #b8c8d7 !important;
  color: white;
}

.noLayout {
  height: 100vh !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
}

.ant-menu-submenu-selected {
  color: #23bcb5 !important;
}
.font18 {
  font-size: 18px !important;
}
.font20 {
  font-size: 20px !important;
}
.font22 {
  font-size: 22px !important;
}

.fontWeight600 {
  font-weight: 600 !important;
}
.font18 {
  font-size: 18px !important;
}

.full-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.full-height {
  height: 100vh;
  overflow-y: scroll;
}

.header {
  height: 50px;
  border-bottom: 1px solid #f0f0f0;
}

.text-center {
  text-align: center;
}

.desktop-layout {
  padding: 0;
}

.cursor {
  cursor: pointer;
}

.bold {
  font-weight: bold !important;
}
.bold-600 {
  font-weight: 600 !important;
}

.light-text {
  font-weight: 400 !important;
}

.center-item {
  display: block;
  text-align: center;
}
.em {
  font-style: oblique;
}
.overflow-hidden {
  overflow: hidden;
}
.white-background,
.ant-btn[disabled].white-background {
  background-color: white !important;
  background: white !important;
  // min-height: 50px;
}

.grey-background {
  background-color: var(--light-background);
}

.secondary-color-background {
  background-color: .crm_app() [--app-background];
  .ant-collapse-item .ant-collapse-content {
    background-color: .crm_app() [--app-background] !important;
  }
}

.light-color-background {
  background-color: var(--light-background) !important;
}

.prescription-color-background {
  background-color: #c1dae8 !important;
}

.ant-drawer-body {
  background-color: #f7f8fa;
}

.ssssss > .ant-drawer-body {
  background-color: red;
}

.secondary-color-background-light {
  background-color: #4da9d040 !important;
}

.danger-ribbon {
  background-color: #fff1f1 !important;
  border: 1px solid #ff5d5d !important;
  padding: 5px 15px;
  padding-bottom: 0px !important;
  border-radius: 6px;
}

// color text
.sub-color-text {
  color: var(--secondary-color) !important;
}

.primary-color-text {
  color: var(--primary-color) !important;
}

.sub-title {
  color: var(--sub-text) !important;
  word-break: break-all;
}

// Buttons
.bordered-secondary-color-btn {
  border: 1px solid var(--secondary-color) !important;
  background-color: transparent !important;
  color: var(--secondary-color) !important;
}

.bordered-mid-color-btn {
  border: 1px solid var(--mid-color) !important;
  background-color: transparent !important;
  color: var(--mid-color) !important;
}

.secondary-color-btn {
  background-color: var(--mid-color) !important;
  color: var(--secondary-color) !important;
  border: none;
}

.page-wrapper {
  height: 100vh;
  position: relative;
}

.page-wrapper {
  .main {
    margin: 7em auto;
    padding: 30px 20px;
    box-shadow: 0 11px 35px 2px rgb(0 0 0 / 14%);
    min-height: 556px !important;
  }

  .login-form {
    margin: 4em auto;
    padding: 50px 40px;
    border-radius: 6px;
  }
}

.heading-text {
  text-align: center;
  font-size: 32px !important;
  font-weight: 600;
  color: var(--heading-color);

  span {
    color: var(--primary-color) !important;
    font-weight: 700 !important;
  }
}

.sub-text {
  font-size: 14px !important;
  color: var(--sub-text);
}

select::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red !important;
  opacity: 1; /* Firefox */
}

.max-subHead-color {
  font-size: 14px;
  color: #163b6d;
  opacity: 0.5;
  font-weight: 400;
}

.text-danger {
  color: red;
}

// margin css
.margin10 {
  margin: 10px 0px !important;
}

.margin5 {
  margin: 5px 0px !important;
}

.margin0 {
  margin: 0 !important;
}

.mt-5 {
  margin-top: -5px;
}
.mt-10 {
  margin-top: -10px;
}

.mt-20 {
  margin-top: -20px;
}
.mt0 {
  margin-top: 0;
}

.mt5 {
  margin-top: 5px;
}

.mb8 {
  margin-bottom: 8px !important;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mmt100 {
  margin-top: -100px !important;
}

.mt200 {
  margin-top: 200px !important;
}

.border-radius {
  border-radius: 6px !important;
}

.border-radius-bottom {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  overflow: hidden !important;
}

.noborder,
.noborder:focus,
.noborder.ant-select-focused {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.noborders,
.noborders:focus,
.noborders.ant-select-focused {
  border: none !important;
  outline: none !important;
}

.border {
  border: 1px solid #e4ebf2;
}

.border-right {
  border-right: 1px solid #e4ebf2;
}
.h66 {
  height: 76px !important;
}

// padding
.padding20 {
  padding: 20px;
}
.paddingTB17 {
  padding: 17px 0px !important;
}
.padding10 {
  padding: 10px;
  .ant-card-body {
    padding: 10px !important;
  }
}

.padding15 {
  padding: 15px;
}

.paddingcollapse {
  padding: 10px 20px;
}
.paddingr5 {
  padding-right: 5px !important;
}
.paddingr10 {
  padding-right: 10px !important;
}
.paddingr20 {
  padding-right: 20px !important;
}
.paddingl20 {
  padding-left: 20px !important;
}
.paddingl10 {
  padding-left: 10px !important;
}
.paddingy20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.paddingy10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.paddingy15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.paddingx20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.paddingtop20 {
  padding-top: 20px;
}
.padding-top {
  padding: 20px 0px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding20LR {
  padding: 10px 20px !important;
}

.nopadding {
  padding: 0px !important;
  .ant-modal-body {
    padding: 0px !important;
  }
}

.padding24 {
  padding: 24px !important;
}

.nomargin {
  margin: -24px !important;
}

.nomarginLR {
  margin-left: -24px !important;
  margin-right: -24px !important;
}

// alignment css
.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.steps-content {
  min-height: 200px;
  padding: 20px;
  background-color: var(--sidebar-background);
  border: none;
  border-radius: 2px;
  margin-top: 20px;
}

.steps-action {
  margin-top: 24px;
}

.cursor {
  cursor: pointer;
}

// Ant design Existing CSS
.custom-tabs .ant-tabs-nav {
  background: rgba(247, 249, 249, 1) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16) !important;
}

// Button
.secondary-button {
  background-color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
}

//  Modal

.ant-modal.appointment-summary {
  padding-bottom: 0px !important;
}

.appointment-summary .ant-modal-header {
  background: var(--secondary-color) !important;
  height: 100px !important;

  .ant-modal-title {
    color: white !important;
  }

  .ant-modal-close {
    color: white !important;
  }
}

.ant-input.noborder:focus,
.ant-input-affix-wrapper-focused.noborder,
.ant-input-affix-wrapper.noborder:focus {
  outline: none !important;
  box-shadow: none !important;
}

.appointment-summary .ant-modal-body {
  background-color: #f7f8fa !important;
}

.positioning-right {
  float: right;
}

.bordered-grey-color {
  border: 1px solid var(--light-background) !important;
  background-color: transparent !important;
  border-radius: 4px;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled)::before {
  border-radius: 6px !important;
}

.ant-radio-button-wrapper {
  border: none !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent !important;
  border: none !important;
}

// all padding css
.pd5 {
  padding: 5px;
}

.pd10 {
  padding: 10px;
}

.pd15 {
  padding: 15px;
}

.pd20 {
  padding: 20px !important;
}

// padding left css
.pdl5 {
  padding-left: 5px;
}

.pdl10 {
  padding-left: 10px;
}
.pdr10 {
  padding-right: 10px;
}

.pdl15 {
  padding-left: 15px;
}

.pdl20 {
  padding-left: 20px !important;
}

// horizontal padding
.pdh5 {
  padding: 0 5px;
}

.pdh10 {
  padding: 0 10px;
}

.pdh20 {
  padding: 0 20px;
}

.pdh30 {
  padding: 0 30px;
}

// margin left css
.mrgl5 {
  margin-left: 5px;
}

.mrg10 {
  margin-left: 10px;
}

.mrg15 {
  margin-left: 15px;
}

.mrg20 {
  margin-left: 20px !important;
}

// font size
.fts10 {
  font-size: 10px;
}

.fts12 {
  font-size: 12px;
}

.fts14 {
  font-size: 14px;
}

.fts16 {
  font-size: 16px;
}
.fts24 {
  font-size: 24px;
}

.fts70 {
  font-size: 70px;
}

.sub-heading {
  font-size: 12px;
  font-weight: 600;
  color: var(--primary-color);
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.lh14 {
  line-height: 14px;
}
.lh18 {
  line-height: 14px;
}
.d-block {
  display: block;
}
//ant collapse
.dark-collapse {
  box-shadow: 0px 4.64552px 15.4851px #9bb9c8 !important;
  .ant-collapse-content-box {
    background-color: .doctor_app() [--sidebar-background] !important;
  }
}
.underline {
  text-decoration: underline;
}
.underline2 {
  padding-bottom: 10px !important;
  border-bottom: 3px solid .doctor_app() [--secondary-color] !important;
}

.ant-input-otp,
.ant-input-otp:focus {
  width: 55px !important;
  height: 55px !important;
  border: none !important;
  background: #ffffff;
  box-shadow: 0px 5.14637px 17.1546px #9bb9c8;
  border-radius: 6.86183px;
  outline: none !important;
  margin: 5px;
  font-size: 18px;
  font-weight: 600;
}
/*
only for mobile users specific for patient web app  
*/
@media (max-width: 767px) {
  .ant-input-otp,
  .ant-input-otp:focus {
    width: 45px !important;
    height: 45px !important;
  }
  .height42 {
    height: 42px;
  }
  .recentAppointmentDivider {
    margin-bottom: 0;
  }
}
.otp-box {
  outline: none !important;
  font-size: 18px;
  height: 60px;
  padding-left: 40px;
  padding-right: 40px;
  letter-spacing: 50px;
  margin-right: -50px;
  font-weight: 600;
}
.otp-box:focus {
  border: none !important;
  outline: none !important;
}
.ant-modal {
  background-color: white !important;
  padding-bottom: 0px !important;
}

.linking-radio .ant-radio-button-wrapper {
  min-height: 65px !important;
}

.noborderWrapper {
  .ant-input,
  .ant-input-group-addon {
    border: none !important;
    height: 60px !important;
  }

  .ant-input:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.letterSpacing80 {
  letter-spacing: 80px !important;
}

.noborder.ant-select-focused .ant-select-selector,
.noborder.ant-select-selector:focus,
.noborder.ant-select-selector:active,
.noborder.ant-select-open .noborder.ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
}

.participant h3 {
  text-transform: capitalize !important;
  text-align: left !important;
}
.select-slot-wrapper {
  border: none;

  border-radius: 0px !important;
  overflow: hidden;
  .timer-dropdown {
    background: 1px solid .crm_app() [--light-color];
  }

  .date-slot-bar {
    background: #ffffff;
    box-shadow: 0px 5.22414px 17.4138px #9bb9c8 !important;
    .secondary-color-background-tab {
      background-color: .doctor_app() [--sidebar-background];
      color: .doctor_app() [--primary-color] !important;
      border-left: 1px solid .doctor_app() [--sidebar-background] !important;
      font-weight: 600 !important;
    }
  }
  .slot-date-card {
    border-left: 1px solid #f0f0f0 !important;
  }
}

.slot-time-picker .ant-collapse-content {
  background-color: .doctor_app() [--sidebar-background];
}

.ant-radio-button-wrapper-checked:hover,
.ant-radio-button-wrapper-checked {
  background: .doctor_app() [--secondary-color] !important;
  border: 1px solid .doctor_app() [--secondary-color];
}

.app-switcher {
  width: 20%;
}

.app-switcher .ant-popover-title {
  padding: 0px !important;
  font-weight: bold;
  font-size: 20px;
  height: 60px;
  background-color: white !important;
  p {
    padding-left: 20px;
    padding-top: 10px;
  }
}

.calander-modal .ant-modal-body {
  background-color: #f8f9fb;

  .ant-picker-calendar .ant-picker-date-panel {
    background: #f8f9fb !important;
  }

  .ant-picker-cell .ant-picker-cell-inner {
    background: #ffffff;
    box-shadow: inset 0px 2px 0px #f0f0f0;
  }
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  height: 100px !important;
}

.btn-shadow {
  box-shadow: 0px 3px 10px 0px rgba(155, 185, 200, 1);
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.dropdown-menu {
  background: #ffffff;
  border: 1.47158px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4.41474px 14.7158px #9bb9c8;
  border-radius: 8.82949px !important;
  overflow: hidden;
  margin-top: 10px;
}

.ant-tag {
  margin-right: 5px !important;
}

.notifications {
  margin-top: 1vh;
  float: right;
}

.ant-modal-confirm {
  text-align: center !important;
}

.ant-modal-confirm-btns {
  float: none !important;
}

.date-tag {
  background-color: #8a9db6 !important;
  background: #8a9db6;
  box-shadow: 0px 1.63269px 3.26539px rgba(49, 19, 46, 0.1);
  border-radius: 32.6539px !important;
  color: white !important;
}
.ant-layout::-webkit-scrollbar {
  display: none;
}

.breakWord {
  word-break: break-all;
}
.choose-profile .ant-radio-wrapper {
  padding: 0 15px !important;
  border-radius: 6.89731px !important;
  background-color: white !important;
  border: 1.72433px solid #fff !important;
}

.choose-profile .ant-radio-wrapper-checked {
  border: 1.72433px solid #23bcb5 !important;
}

.login-name {
  font-size: 16px !important;
}

// Steps

.square-timeline {
  .ant-steps-item-icon {
    border-radius: 2px !important;
    border: none !important;
    width: 22.87px !important;
    height: 22.87px !important;
    background: white !important;
    border: 1.84211px solid rgba(22, 59, 109, 0.5) !important;
    .ant-steps-icon {
      color: transparent !important;
    }
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: white !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: .doctor_app() [--secondary-color] !important;
    border: none !important;
    color: white !important;
    svg {
      fill: white !important;
    }
  }

  .ant-steps-item-icon .ant-steps-icon {
    top: -3.5px !important;
  }
}

.black-background {
  background-color: black !important;
}

.selected-patient .user-frame {
  background: white !important;
}

.user-frame {
  background: linear-gradient(0deg, #e4f3f3, #e4f3f3) !important;
  border-radius: 9.5407px !important;
  color: .doctor_app() [--secondary-color] !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.doctor-success-message.ant-result-success .ant-result-icon > .anticon {
  color: .doctor_app() [--secondary-color] !important;
}
.admin-app {
  .crm-form {
    padding: 0 !important;
    .ant-form-item {
      margin-bottom: 0 !important;
    }
    .ant-col {
      padding-left: 5px !important;
      padding-right: 0px !important;
    }
  }
  .crm-table {
    .ant-table-title {
      padding: 0 0 10px 0px;
      background-color: .doctor_app() [--app-background];
    }
    .ant-table-thead tr th {
      background: #eef2f9;
    }
    thead th {
      white-space: nowrap;
      word-break: break-word;
      word-break: break-all;
    }
    .ant-pagination li {
      min-width: 26px;
      height: 26px;
      line-height: 26px;
      button {
        font-size: 9px;
      }
    }
  }
}

.dark-table {
  border-radius: 5px !important;
  box-shadow: inset 0px -1px 0px #d0d7df;
  .ant-table-thead > tr > th {
    background: .doctor_app() [--sidebar-background] !important;
  }
}
.expandable-iframe {
  .iframe-hover-button {
    position: absolute;
    display: block;
    top: 70px;
    left: 8px;
    color: #ffffff !important;
    background-color: rgba(0, 0, 0, 0.7);
  }
}
.d-none {
  display: none;
}
.full-screen-iframe {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  .expandable-iframe {
    margin-top: 5vh;
    margin-left: 5vw;
    margin-right: 5vw;
  }
  .iframe-hover-button-close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #ffffff !important;
  }
}

.select-address {
  .ant-select-selector {
    height: auto !important;
    .ant-select-selection-item {
      height: auto !important;
      white-space: normal !important;
    }
  }
}

.ant-select-item-option-content {
  white-space: normal !important;
}

// font family nova

.Proxima_Nova_Bold {
  font-family: proxima-nova, sans-serif;

  font-weight: 700;

  font-style: normal;
}

.Proxima_Nova_Semibold {
  font-family: proxima-nova, sans-serif;

  font-weight: 600;

  font-style: normal;
}

.Proxima_Nova_Regular {
  font-family: proxima-nova, sans-serif;

  font-weight: 400;

  font-style: normal;
}

.Proxima_Nova_Italic {
  font-family: proxima-nova, sans-serif;

  font-weight: 400;

  font-style: italic;
}

.Proxima_Nova_Medium {
  font-family: proxima-nova, sans-serif;

  font-weight: 500;

  font-style: normal;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "proxima-nova" !important;
  font-weight: 400;
}

.hauto {
  height: auto;
}

/*
only for mobile users specific for patient web app
*/
@media (max-width: 767px) {
  h3.ant-typography,
  .ant-typography h3 {
    font-size: 18px;
  }
}

.text-center-content {
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  margin: auto;
  display: flex;
}

.relative {
  position: relative !important;
  justify-content: center;
  display: flex;
  align-items: center;
}

.participants-avatar {
  position: absolute;
  height: 150px;
  width: 150px;
  color: .doctor_app() [--primary-color] !important;
  font-size: 40px !important;
  padding: 60px;
  font-weight: 600;
}

.participants-avatar-other-party {
  position: absolute;
  height: 25%;
  width: 15%;
  color: .doctor_app() [--primary-color] !important;
  font-size: 40px !important;
  padding: 80px;
  font-weight: 600;
  left: 43%;
  top: 30%;
}

// Tag Color

.tag-green {
  background: #37cb954f;
  border-radius: 4px;
  color: #37cb95 !important;
  border: none !important;
}

.success-tag {
  min-width: 106.53px;
  background: #37cb954f;
  border-radius: 4px;
  color: #37cb95 !important;
  border: none !important;
  text-align: center;
  padding: 5px;
}

.tag-red {
  background: #e1454e47;
  border-radius: 4px;
  color: #e1454e !important;
  border: none !important;
}

.tag-orange {
  background: #eb872a47;
  border-radius: 4px;
  color: #eb872a !important;
  border: none !important;
}

.danger-tag {
  min-width: 106.53px;
  background: #e1454e47;
  border-radius: 4px;
  color: #e1454e !important;
  border: none !important;
  text-align: center;
  padding: 5px;
}

.boss-table {
  .paddingx20 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .crm-table {
    .ant-table-title {
      padding: 0 0 0px 0px;
      background-color: rgb(247, 248, 251) !important;
    }
    .ant-table-thead tr th {
      background: #eef2f9;
    }
    thead th {
      white-space: nowrap;
      word-break: break-word;
      word-break: break-all;
    }
    .ant-pagination li {
      min-width: 26px;
      height: 26px;
      line-height: 26px;
      button {
        font-size: 9px;
      }
    }
  }
}

.instant-tag {
  border: 1px solid #223042;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #223042;
}

.nopadding-tab {
  .padding10 {
    padding: 0 !important;
  }
}

.crm-size-bar-namecard {
  color: .crm_app() [--text-secondary-color] !important;
  line-height: 16px !important;
  padding-top: 5px !important;
}

//for LoginMobileBanner
#mask0_2480_572 {
  mask-type: alpha;
}

//color for placeholder
input::placeholder {
  color: #b9c4d3;
}
//color for disabled number
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #23bcb5;
}

.user-nav-letter-circle {
  height: 30px !important;
  width: 30px !important;
  padding: 8px 0;
  font-size: 14px !important;
  line-height: 15px;
  background-color: #ffe2e3;
  color: #e1454e;
  filter: none !important;
  text-align: center !important;
  border-radius: 100% !important;
}
.marginAll20 {
  margin: 20px;
}

.hms-ui-c-jMKPkp {
  background-color: transparent !important;
}

.ant-layout-sider-zero-width-trigger {
  background: #ffffff !important;
  border: none !important;
  top: 6px !important;
  color: #000000 !important;
  border-left: 1px solid #dddddd !important;
  text-align: right;
}
.ant-layout-sider-collapsed .ant-layout-sider-zero-width-trigger {
  width: 38px !important;
  text-align: right;
  left: 0;
  border-left: none !important;
}
@media (max-width: 767px) {
  .ticket-appointment-radios.ant-radio-button-wrapper {
    min-height: 130px !important;
  }
}
.allAppsHead {
  color: #003873 !important;
  font-weight: 600 !important;
  font-size: 18.76px !important;
}

.modalStyle2 .ant-modal-title {
  color: #003873 !important;
  font-weight: 700 !important;
  font-size: 20.6125px !important;
}

.build-time {
  display: none;
}

.chat .ant-drawer-footer {
  background-color: #edeef1 !important;
}

.left-message {
  border: 1px solid lightgray;
  background: #ffffff;
  box-shadow: 0px 1.63269px 1.63269px rgba(49, 19, 46, 0.15);
  border-radius: 14.6942px;
  padding: 16px 20px;
  min-width: 20% !important;
  max-width: 50% !important;
  padding-bottom: 20px;
  float: left;
  display: inline-block;
  word-break: break-all;
}

.right-message {
  background: .doctor_app() [--secondary-color] !important;
  box-shadow: 0px 1.63269px 1.63269px rgba(49, 19, 46, 0.15);
  border-radius: 14.6942px;
  padding: 16px 20px;
  color: white !important;
  min-width: 20% !important;
  max-width: 50% !important;
  float: right;
  display: inline-block;
  word-break: break-all;
}
.left-message-nps {
  border: 1px solid lightgray;
  background: #ffffff;
  box-shadow: 0px 1.63269px 1.63269px rgba(49, 19, 46, 0.15);
  border-radius: 14.6942px;
  padding: 10px 16px;
  min-width: 20% !important;
  max-width: 70% !important;
  margin-bottom: 3px;
  float: left;
  display: inline-block;
  word-break: break-all;
}

.right-message-nps {
  background: .doctor_app() [--secondary-color] !important;
  box-shadow: 0px 1.63269px 1.63269px rgba(49, 19, 46, 0.15);
  border-radius: 14.6942px;
  padding: 10px 16px;
  color: white !important;
  min-width: 20% !important;
  max-width: 30% !important;
  float: right;
  display: inline-block;
  word-break: break-all;
  margin-bottom: 5px;
}
.selected-patient {
  background-color: #5ab9b421 !important;
  border-right: 8px solid var(--secondary-color);
}
